<template>
  <div>
    <div class="quadro-test no-selection">
      <a-modal
        ref="popup"
        :title="$t('temp.quadro-test')"
        :cancel-text="$t('buttons.cancel')"
        :ok-text="$t('buttons.confirm')"
        :visible="!testStarted"
        @ok="onConfirmDialogue"
        @cancel="onCancelDialogue"
      >
        <h4 class="text-muted text-left">
          {{ $t("quadro-test.noticeLine1") }}
        </h4>
        <h4 class="text-success text-left">
          {{ $t("quadro-test.noticeLine2") }}
        </h4>
        <h4 class="text-danger text-left">
          {{ $t("quadro-test.noticeLine3") }}
        </h4>
        <h4 class="text-success text-left">
          {{ $t("quadro-test.noticeLine33") }}
        </h4>
        <h4 class="text-muted text-left">
          {{ $t("quadro-test.noticeLine4") }}
        </h4>
      </a-modal>
      <a-modal
        v-model="showIntermediateModel"
        :title="$t('temp.quadro-test')"
        on-ok="handleOk"
      >
        <template slot="footer">
          <!-- <a-button key="back" @click="handleCancel">Return</a-button> -->
          <a-button key="submit" type="primary" @click="handleOk">
            {{ $t("buttons.confirm") }}
          </a-button>
        </template>
        <p class="red-popup">
          {{ $t("quadro-test.titleBad") }}
        </p>
      </a-modal>
      <div v-if="resultsVisible ? false : true" id="data_collection_step">
        <div>
          <h3 v-if="testStepIndex === 0" class="green-text">
            {{ $t("quadro-test.titleGood") }}
          </h3>
          <h3 v-else-if="testStepIndex === 1" class="red-text">
            {{ $t("quadro-test.titleBad") }}
          </h3>
          <h3 v-else-if="testStepIndex === 2" class="green-text">
            {{ $t("quadro-test.titlePortrait") }}
          </h3>

          <transition-group
            id="grid"
            tag="ul"
            name="figure-out-in"
            class="grid"
          >
            <div v-for="item in filteredArray" :key="item.key">
              <span class="figure">
                <transition name="figure">
                  <img
                    v-if="item.show"
                    :class="item.class"
                    :src="item.url"
                    @click="cliccer(item)"
                  />
                </transition>
              </span>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
const moment = require("moment-timezone");

export default {
  middleware: "uiDefaultState",
  data() {
    return {
      canClick: true,
      testStarted: false,
      resultsVisible: false, // default:false,
      testStepIndex: 0,

      prevSymb: "–",
      frontData: [],
      backData: [],

      sortedPhotos: [],

      // eslint-disable-next-line no-unused-vars
      loadedMaterialsArr: [],

      loadedMaterialsArrPersons: [],

      dataId: "",
      transactions: " ",
      lastMoment: null,

      showIntermediateModel: false,
      data: {},
    };
  },
  computed: {
    testsList() {
      return this.getTestsList;
    },
    ...mapGetters([
      "getTestsList",
      // ...
    ]),
    filteredArray() {
      if (this.frontData.length < 16) {
        if (this.frontData.length === 8) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.loadedMaterialsArr = this.loadedMaterialsArr.filter(function (
            phItem
          ) {
            return phItem.class !== "hiddenPhoto";
          });
        }
        return this.loadedMaterialsArr;
      } else if (this.backData.length === 0) {
        return this.loadedMaterialsArrPersons;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    this.setHeaderFooterVis({
      visibleHeader: false,
      visibleFooter: false,
    });

    const testUrl = "/tests/quadro";
    const testLists = this.testsList;
    const test = testLists.find(function (element) {
      return element.url === testUrl;
    });
    if (!test) {
      this.$router.push("/");
      return;
    }

    this.importAll(test.data.images, true);

    this.importAll(test.data.persons, false);

    const timeStart = moment.tz("Europe/Kiev");
    this.lastMoment = timeStart;
    this.dataId = "";

    this.data.datestart = timeStart.format();
    this.data.testtype = "Quadro";
    this.data.transactions = this.transactions;
    this.data.result = {};

    // const show = false;
    // if (!process.server) {
    //   const x = document.getElementById('__replain_widget_iframe');
    //   if (x) {
    //     x.style.display = show ? 'unset' : 'none';
    //   }
    // }
    // await this.waitFor(2000);
    // if (!process.server) {
    //   const x = document.getElementById('__replain_widget_iframe');
    //   if (x) {
    //     x.style.display = show ? 'unset' : 'none';
    //   }
    // }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.resultsVisible && this.testStarted) {
      const modal = this.$confirm();
      modal.update({
        title: this.$t("test.stoptest"),
        content: this.$t("test.stoptestnotice"),
        okText: this.$t("buttons.confirm"),
        cancelText: this.$t("buttons.cancel"),
        onOk() {
          next(true);
          modal.destroy();
        },
        onCancel() {
          next(false);
          modal.destroy();
        },
      });
      return;
    }

    if (to === from) {
      // this.$router.go({ path: to, force: true })
      this.$router.push("/");
      return;
    }
    next(true);
  },
  methods: {
    handleOk() {
      this.showIntermediateModel = false;
    },
    ...mapMutations({ setHeaderFooterVis: "ui/setHeaderFooterVis" }),
    importAll(items, isImages) {
      const imgs = [];
      items.forEach((
        item,
        index // .keys()
      ) =>
        imgs.push({
          url: this.$strapi.storageUrl + item.url,
          id: item,
          class: "figure",
          show: true,
          key: "figure" + item.key + index,
        })
      );
      if (isImages) {
        this.loadedMaterialsArr = imgs;
      } else {
        this.loadedMaterialsArrPersons = imgs;
      }
    },

    onConfirmDialogue() {
      this.testStarted = true;
    },
    onCancelDialogue() {
      this.$router.push("/");
    },

    async fillData() {
      if (this.frontData.length === 0) {
        this.resultedDataContainer = {
          images: this.loadedMaterialsArr.slice(0, 16),
          person: this.backData[0],
        };
        return;
      }

      this.resultedDataContainer = {
        images: this.frontData,
        person: this.backData,
      };

      const timeEnd = moment.tz("Europe/Kiev");

      this.data.dateend = timeEnd.format();
      this.data.status = "Пройден";
      this.data.result = this.resultedDataContainer;
      this.data.transactions = this.transactions;
      this.data.finished = true;

      const response = await this.$store.dispatch(
        "testResults/createTestresult",
        this.data
      );
      this.dataId = response.udid;
    },
    // eslint-disable-next-line require-await
    async waitFor(ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms || 0);
      });
    },
    updateLastMoment() {
      const timeEnd = moment.tz("Europe/Kiev");
      this.lastMoment = timeEnd;
    },
    async cliccer(item) {
      if (!item.show || !this.canClick) {
        return;
      }

      item.show = false;
      this.canClick = false;

      await this.waitFor(300);

      // eslint-disable-next-line require-atomic-updates
      item.class = "hiddenPhoto";

      if (this.frontData.length < 16) {
        // front
        this.frontData.push(item.id);
      } else {
        // back
        this.backData.push(item.id);
      }
      if (this.frontData.length === 16) {
        this.testStepIndex = 2;
      }
      /// ////////////////////
      const timeEnd = moment.tz("Europe/Kiev");
      const duration = moment
        .duration(timeEnd.diff(this.lastMoment))
        .asSeconds();

      // this.data.transactions += duration.toString() + ' '
      this.transactions += duration.toString() + " ";

      // -----------end
      if (this.backData.length === 1) {
        this.resultsVisible = true;

        await this.fillData();

        const url = "/results/" + this.dataId;
        this.$router.push(url);
      } else {
        if (this.frontData.length === 8) {
          this.testStepIndex = 1;
          this.showIntermediateModel = true;
        }
      }

      this.canClick = true;

      this.updateLastMoment();
    },
  },
};
</script>

<style scoped>
.green-text {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: green;
  font-weight: bold;
  font-size: 1.5em;
}

.red-text {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: red;
  font-weight: bold;
  font-size: 1.5em;
}

.figure {
  justify-self: center;
  min-width: calc(100vw / 4 - 20px);
  min-height: calc((100vw / 4 - 20px) / 0.71);
}

.figure-enter-active,
.figure-leave-active {
  transition: opacity 1.5s;
}
.figure-enter,
.figure-leave-to {
  opacity: 0;
}

.figure-out-in-enter-active {
  transition: opacity 1s;
}

.figure-out-in-leave-active {
  transition: opacity 0s;
  opacity: 0;
}

.figure-out-in-enter-active {
  transition-delay: 0.7s;
}

.figure-out-in-enter,
.figure-out-in-leave-to {
  opacity: 0;
}

/* Transitions using the page hook */
.page-enter-active,
.page-leave-active {
  transition: all 0.3s ease-out;
}
.page-enter,
.page-leave-active {
  opacity: 0;
  transform-origin: 50% 50%;
}

.demo {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  touch-action: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}

h3 {
  text-align: center;
}

#grid {
  display: grid;
  grid-template-columns: repeat(4, 0.9fr);
  justify-content: center;
  justify-items: center;
  grid-gap: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.red-popup {
  color: red;
  font-size: large;
}

#grid div {
  display: grid;
  /* justify-items: center; */
  justify-self: center;
}

.grid img {
  position: relative;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  max-width: 90%;
}

/*    Padding Ul   */
.quadro-test ul {
  padding: 0px;
}
</style>
